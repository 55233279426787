.round-border{
    border-radius: 10px !important;
}
.pointer-on-hover{
    cursor: pointer;
}

.shadow-2{
    -webkit-box-shadow: 2px 2px 3px 0 rgba(186,186,186,1);
    -moz-box-shadow: 2px 2px 3px 0 rgba(186,186,186,1);
    box-shadow: 2px 2px 3px 0 rgba(186,186,186,1);
}
*{
    font-family: Roboto,sans-serif;
    font-style: normal;
}