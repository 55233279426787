.mobile-container{
    border: 10px solid #111;
    border-radius: 34px;
    width:300px;
    padding: 0;
    height: 520px;
}
.mobile-container::-webkit-scrollbar{
    width: 5px;

}
.mobile-container::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 20px; 
}

.mobile-container::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 20px;
}
/* Track */
.mobile-container::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    height:50%

}

/* Handle */
.mobile-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;

}

/* Handle on hover */
.mobile-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.mobile-container .notch {
    position: relative;
    top: 0;
    width: 150px;
    height: 30px;
    margin: 0 auto;
    background-color: #111;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 18px;
}
