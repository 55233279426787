.link-card-input{
    background-color: #f3f4f8;
    outline: none;
    border: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.link-card-input:focus{
    background-color: #f3f4f8;
}
