.landing-page-nav-button {
  color: #5b7dfb;
  border-color: #5b7dfb;
  width: 156px;
}
.landing-page-nav-button:hover {
  background-color: #5b7dfb;
}
.jumbo {
  .typography {
    .heading {
      font-weight: bold;
      font-size: 50px;
      line-height: 50px;

      color: #494949;
      .blue-text {
        color: #5b7dfb;
      }
    }
    .text {
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
      color: #858585;
    }
    .sign-up-button {
      background: #5b7dfb;
      border-radius: 8px;
      width: 200px;
    }
  }
}
.features {
  .header {
    .heading {
      font-weight: bold;
      font-size: 42px;
      line-height: 50px;
      text-align: center;
      color: #494949;
    }
    .text {
      font-weight: 500;
      font-size: 22px;
      line-height: 34px;

      text-align: center;

      color: #858585;
    }
  }

  .feature-list {
    .feature {
      .icon-holder {
        background: #5b7dfb;
        border-radius: 10px;
        width: 90px;
        height: 90px;
      }
      .heading {
        font-weight: bold;
        font-size: 30px;
        line-height: 50px;
        /* or 167% */

        color: #494949;
      }
      .text {
        font-weight: 500;
        font-size: 22px;
        line-height: 34px;
        /* or 155% */

        text-align: center;

        color: #858585;
      }
    }
  }
}

.testimonials {
  font-family: Roboto;
  font-style: normal;
  background-color: #e7ecfecc;

  .heading {
    font-weight: bold;
    font-size: 42px;
    line-height: 50px;
    text-align: center;
    /* or 119% */

    color: #494949;
  }
  .list {
    .testimonial {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
      background: white;
      text-align: center;
      .text {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        /* or 145% */

        text-align: center;

        color: #858585;
      }
      .name {
        font-weight: bold;
        font-size: 26px;
        line-height: 50px;
        /* or 192% */

        color: #494949;
      }
      .designation {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        /* identical to box height, or 145% */

        text-align: center;

        color: #858585;
      }
    }
  }
}

.plans {
  .heading {
    font-weight: bold;
    font-size: 42px;
    line-height: 50px;
    /* or 119% */

    color: #494949;
    
  }
  .plan-card {
    background: rgba(231, 236, 254, 0.8);
    border-radius: 10px;
    .header {
      .heading {
        font-weight: 500;
        font-size: 40px;
        line-height: 30px;
        /* or 75% */

        color: #494949;
        .underline {
          background-color: #5b7dfb;
          height:4px;
          width:78px;
        }
      }
      .pricing {
        .price {
          font-weight: bold;
          font-size: 20px;
          line-height: 20px;
          /* or 150% */

          color: #494949;
        }
        .description {
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          /* or 267% */

          color: #858585;
        }
      }
    }
    .plan-details {
      .this-plan-includes {
        font-weight: bold;
        font-size: 26px;
        line-height: 50px;
        /* or 192% */

        color: #494949;
      }
      .plan-text {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        /* identical to box height, or 145% */

        color: #858585;
      }
    }
    .plan-button {
      background: #5b7dfb;
      border-radius: 8px;
    }
  }
  .plan-card-invert {
    background: #5B7DFB;
    border-radius: 10px;
    .header {
      .heading {
        font-weight: 500;
        font-size: 40px;
        line-height: 30px;
        /* or 75% */

        color: white;
        .underline {
          background-color: white;
          height:4px;
          width:78px;
        }
      }
      .pricing {
        .price {
          font-weight: bold;
          font-size: 20px;
          line-height: 20px;
          /* or 150% */

          color: white;
        }
        .description {
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          /* or 267% */

          color: white;
        }
      }
    }
    .plan-details {
      .this-plan-includes {
        font-weight: bold;
        font-size: 26px;
        line-height: 50px;
        /* or 192% */

        color: white;
      }
      .bullet{
        fill:white;
      }
      .plan-text {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        /* identical to box height, or 145% */

        color: white;
      }
    }
    .plan-button {
      background: white;
      color: black;
      border-radius: 8px;
    }
  }
}

.footer {
  background-color: #e7ecfecc;
  .for-any-query {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    /* identical to box height, or 145% */

    color: #858585;
  }
  .email {
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    /* or 192% */

    text-decoration-line: underline;

    color: #5b7dfb;
  }
  .follow-us-on {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    /* identical to box height, or 145% */

    color: #858585;
  }
  .social-link {
    background-color: #858585;
    width: 29px;
    height: 29px;
  }
}
