.customize-heading {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;

    color: #494949;
}
.customize-heading:focus {
    box-shadow: none !important;
}
