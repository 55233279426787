.heading{
    font-size: 16px;
    line-height: 19px;
    padding: 5px;
    color: #A6A6A6;
}
.item{
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin: 10px;
    color: #858585;
    cursor: pointer;
}
.itemSelected{
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin: 10px;
    color: #5B7DFB;
    cursor: pointer;
}
.userTab{
    background: #FAFBFE;
    border-radius: 5px;
    padding: .5em;
}
.username{
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #494949;
}
.userEmail{
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    white-space: normal;
    word-break:break-all;
    color: #A6A6A6;
}
.subItemUnselected{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    
    
    color: #A6A6A6;
}
.subItemSelected{
    font-weight: bold;
font-size: 16px;
line-height: 19px;
/* identical to box height */


color: #494949;
}